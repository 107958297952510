@tailwind base;
@tailwind components;
@tailwind utilities;

.min-h-screen-minus-100 {
  min-height: calc(100vh - 100px);
}

.markdown > * {
  all: revert;
}

body {
  @apply bg-light;
}

@layer base {
  :root {
    --radius: 0.5rem;
  }
}
